@media (min-width: 991px) and (max-width:1440px) {
    .footer-row{
        margin-left: 110px;
        margin-top: 1px;
    }
}


@media (max-width: 991px) {
    .footer-row {
      display: flex;
      flex-wrap: wrap;
    }
  
    .footer-column {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 575px) {
    .footer-column {
      flex: 0 0 100%;
      max-width: 100%;
    }

    

  }
    