.navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #011c29f3;
}

  
  .logo-img {
    max-height: 50px;
    width: auto;
  }
  
  .navbar-nav .nav-link {
    color: inherit !important;
    padding: 0.5rem 1rem !important;
  }
  
  .custom-toggler {
    border: none;
    background: transparent;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    outline: none;
  }
  
  .custom-toggler span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  .custom-toggler span:nth-child(1) {
    top: 0px;
  }
  
  .custom-toggler span:nth-child(2) {
    top: 10px;
  }
  
  .custom-toggler span:nth-child(3) {
    top: 20px;
  }
  
  .custom-toggler.open span:nth-child(1) {
    top: 10px;
    transform: rotate(135deg);
  }
  
  .custom-toggler.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .custom-toggler.open span:nth-child(3) {
    top: 10px;
    transform: rotate(-135deg);
  }

  body.no-scroll {
    overflow: hidden;
  }
  
  @media (max-width: 991px) {
    .navbar-collapse {
      background-color: #011c29e0;
      padding: 1rem;
      margin-top: 0.5rem;
      border-radius: 0.25rem;
      height: 100vh;
      
    }
  
    .navbar-nav .nav-link {
      padding: 0.75rem 1rem !important;
    }
  
    .btn-danger {
      display: block !important;
      margin: 1rem auto 0;
      width: 100%;
    }
  }