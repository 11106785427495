.contact-box {
    position: fixed;
    top: -140%;
    left: 0;
    right: 0;
    background-color: #262121d0;
    padding: 20px;
    padding-top: 88px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: top 0.3s ease-in-out;
    z-index: 100;
  }
  
  .contact-box.show {
    top: 0;
    height: 100vh;
  }
  
  .contact-box-content {
    max-width: 700px;
    margin: 0 auto;
  }

  .close {
    position: fixed;
    top: 20px;
    right: 30px;
    border-radius: 40%;
    background-color: rgba(215, 41, 41, 0) !important;
    height: auto;
  width: auto;
    display: flex;
    cursor: pointer;
    z-index: 101;
    color: rgb(249, 16, 16) !important;
    font-size: 2rem;  
}
 
  
  .contact-box button {
    background-color: #1382dd;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .contact-box h2 {
    color: aliceblue;
  }

  .no-scroll {
    overflow: hidden;
  }

  @media (max-width:480px){
    .contact-box{
        background-color: #262121d0;
    }

    .close{
     top:20px;
     right: 20px;
    }
  }

 
